import React, {lazy, memo, Suspense} from 'react'
import 'react-day-picker/dist/style.css'
import {Route, Switch} from 'react-router'
import useServiceWorkerUpdater from '../../hooks/useServiceWorkerUpdater'
import useTrebuchetEvents from '../../hooks/useTrebuchetEvents'
import {LoaderSize} from '../../types/constEnums'
import {CREATE_ACCOUNT_SLUG, SIGNIN_SLUG} from '../../utils/constants'
import ErrorBoundary from '../ErrorBoundary'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import PrivateRoutes from '../PrivateRoutes'
import Snackbar from '../Snackbar'

const AnalyticsPage = lazy(() => import(/* webpackChunkName: 'AnalyticsPage' */ '../AnalyticsPage'))
const AuthenticationPage = lazy(
  () => import(/* webpackChunkName: 'AuthenticationPage' */ '../AuthenticationPage')
)
const DemoMeeting = lazy(() => import(/* webpackChunkName: 'DemoMeeting' */ '../DemoMeeting'))
const DemoSummary = lazy(() => import(/* webpackChunkName: 'DemoSummary' */ '../DemoSummary'))
const AuthProvider = lazy(() => import(/* webpackChunkName: 'AuthProvider' */ '../AuthProvider'))
const SAMLRedirect = lazy(() => import(/* webpackChunkName: 'SAMLRedirect' */ '../SAMLRedirect'))
const SetNewPassword = lazy(
  () => import(/* webpackChunkName: 'SetNewPassword' */ '../ResetPasswordPage/SetNewPassword')
)
const VerifyEmail = lazy(() => import(/* webpackChunkName: 'VerifyEmail' */ '../VerifyEmail'))
const TeamInvitation = lazy(
  () => import(/* webpackChunkName: 'TeamInvitationRoot' */ '../TeamInvitationRoot')
)
const InvitationLink = lazy(
  () => import(/* webpackChunkName: 'InvitationLinkRoot' */ '../InvitationLinkRoot')
)

const Action = memo(() => {
  useTrebuchetEvents()
  useServiceWorkerUpdater()
  const isInternalAuthEnabled = window.__ACTION__.AUTH_INTERNAL_ENABLED
  return (
    React.createElement(React.Fragment, null
      , React.createElement(ErrorBoundary, null
        , React.createElement(Snackbar, null )
        , React.createElement(Suspense, { fallback: React.createElement(LoadingComponent, { spinnerSize: LoaderSize.WHOLE_PAGE,} ),}
          , React.createElement(AnalyticsPage, null )
          , React.createElement(Switch, null
            , React.createElement(Route, { exact: true, path: "/", render: (p) => React.createElement(AuthenticationPage, { ...p, page: 'signin',} ),} )
            , React.createElement(Route, {
              exact: true,
              path: `/${SIGNIN_SLUG}`,
              render: (p) => React.createElement(AuthenticationPage, { ...p, page: 'signin',} ),}
            )
            , React.createElement(Route, {
              exact: true,
              path: `/${CREATE_ACCOUNT_SLUG}`,
              render: (p) => React.createElement(AuthenticationPage, { ...p, page: 'create-account',} ),}
            )
            , React.createElement(Route, { exact: true, path: `/auth/:provider`, component: AuthProvider,} )
            , React.createElement(Route, { path: `/saml-redirect`, component: SAMLRedirect,} )
            , React.createElement(Route, {
              path: "/retrospective-demo/:localPhaseSlug?/:stageIdxSlug?",
              component: DemoMeeting,}
            )
            , React.createElement(Route, { path: "/retrospective-demo-summary/:urlAction?", component: DemoSummary,} )
            , isInternalAuthEnabled && (
              React.createElement(Route, {
                exact: true,
                path: `/forgot-password`,
                render: (p) => React.createElement(AuthenticationPage, { ...p, page: 'forgot-password',} ),}
              )
            )
            , isInternalAuthEnabled && (
              React.createElement(Route, {
                path: `/forgot-password/submitted`,
                render: (p) => React.createElement(AuthenticationPage, { ...p, page: `forgot-password/submitted`,} ),}
              )
            )
            , React.createElement(Route, {
              path: "/verify-email/:verificationToken/:invitationToken?",
              component: VerifyEmail,}
            )
            , React.createElement(Route, { path: "/reset-password/:token", component: SetNewPassword,} )
            , React.createElement(Route, { path: "/team-invitation/:token", component: TeamInvitation,} )
            , React.createElement(Route, { path: "/invitation-link/:token", component: InvitationLink,} )
            , React.createElement(Route, { component: PrivateRoutes,} )
          )
        )
      )
    )
  )
})

Action.displayName = 'Action'

export default Action
